import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Brochure from './components/modals/Brochure';
import './scss/nosotros.scss';
import p11 from './assets/images/new/p1-1.jpg';
import p12 from './assets/images/new/p1-2.jpg';
import p21 from './assets/images/new/p2-1.png';
import p22 from './assets/images/new/p2-2.png';
import p31 from './assets/images/new/p3-1.png';
import p32 from './assets/images/new/p3-2.png';
import p41 from './assets/images/new/p4-1.png';
import p42 from './assets/images/new/p4-2.png';
import p51 from './assets/images/new/p5-1.png';
import p61 from './assets/images/new/p6-1.png';
import p71 from './assets/images/new/p7-1.png';
import p81 from './assets/images/new/p8-1.png';
import p91 from './assets/images/new/p9-1.png';
import p101 from './assets/images/new/p10-1.png';
import p111 from './assets/images/new/p11-1.png';
class Nosotros extends Component {
    constructor(props){
        super(props);
        let original_state = {
            kab: false,
            hun: false,
            show: false
        };
        if(this.props.location.hash === "#casaKab"){
            original_state.kab = true;
        }
        if(this.props.location.hash === "#casaHun"){
            original_state.hun = true;
        }
        this.state = original_state;
    }
    setShow = (bool) => {
        this.setState({...this.state, show: bool});
    }
    
    render() {
        return (
            <div id="houses">
                <Brochure show={this.state.show} setShow={this.setShow}></Brochure>
                <div className="section-1">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                                <h1 className="text-uppercase mw-75 titulo-x1">
                                    <FormattedMessage id="app.nosotros" defaultMessage="Nosotros"></FormattedMessage>
                                </h1>
                                
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5"></span>
                                <p className="text-1 text-grey-2">
                                    <FormattedMessage id="app.nosotrostxt" defaultMessage="Las casas en LAJA® se crearon con la emoción de tener todos esos grandes detalles que ayudan a disfrutar el equilibrio entre el diseño, el arte y la funcionalidad."></FormattedMessage>
                                </p>
                                <p className="text-1 text-grey-2">
                                    <FormattedMessage id="app.nosotrostxt2" defaultMessage="Las Casas Ensamble logran un estilo de vida único en su tipo con la capacidad de integrar lo mejor de la esencia orgánica y lo material en un mismo espacio perfectamente diseñado."></FormattedMessage>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-1">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                                <h1 className="text-uppercase mw-75 titulo-x1">
                                    <FormattedMessage id="app.nosotrosPro" defaultMessage="Nosotros"></FormattedMessage>
                                </h1>
                                
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Neek Kaan:</span>
                                <p className="text-1 text-grey-2"> 41 departamentos <br /> Ubicados a 1.8km del centro en Progreso, Yuc. <br /> Fecha de finalización 2010 </p>
                                <p className="text-1 text-grey-2">
                                <img src={p11} alt="arrow_down" className='p11 img-fluid'></img>
                                <img src={p12} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Archipielago 9:</span>
                                <p className="text-1 text-grey-2"> 7 villas que se construyó frente al mar<br /> San Bruno Yucatán. <br /> Fecha finalizacion 2013</p>
                                <p className="text-1 text-grey-2">
                                <img src={p21} alt="arrow_down" className='p11 img-fluid'></img>
                                <img src={p22} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Muranta:</span>
                                <p className="text-1 text-grey-2">22 departamentos divididos en 2 edificios, cada edificio con 2 módulos<br /> Ubicación Temozon <br /> Fecha finalizacion 2017.</p>
                                <p className="text-1 text-grey-2">
                                <img src={p31} alt="arrow_down" className='p11 img-fluid'></img>
                                <img src={p32} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Restaurante Hermana República y Cerveceria Patito</span>
                                <p className="text-1 text-grey-2">Xcanatun Yucatan <br /> Fecha finalizacion: 2015</p>
                                <p className="text-1 text-grey-2">
                                <img src={p41} alt="arrow_down" className='p11 img-fluid'></img>
                                <img src={p42} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">CORDELIA (2018-2020)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 14 departamentos<br />Ubicación Tulum</p>
                                <p className="text-1 text-grey-2">
                                <img src={p51} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Gaviones</span>
                                <p className="text-1 text-grey-2">Privada con 26 lotes y amenidades <br />Ubicación Cholul<br />Fecha finalizacion 2021</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">Parque Patito</span>
                                <p className="text-1 text-grey-2">Locales comerciales<br />Fecha finalización: 2022</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            <h1 className="text-uppercase mw-75 titulo-x1">
                            PROYECTOS <br /> CONCORD
                                </h1>
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">SABINA (2020-2022)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 36 Departamentos <br />Temozón Norte</p>
                                <p className="text-1 text-grey-2">
                                <img src={p61} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">MATALÍ (2020-2022)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 12 Departamentos<br />Santa Gertrudis Copó</p>
                                <p className="text-1 text-grey-2">
                                <img src={p71} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">CARMINA (2020-2022)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 24 Departamentos<br />Temozón Norte</p>
                                <p className="text-1 text-grey-2">
                                <img src={p81} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">SUSTENTA (2020-2022)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 8 Departamentos<br />Dzityá</p>
                                <p className="text-1 text-grey-2">
                                <img src={p91} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">TIRRENO (2015-2017)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 12 Departamentos<br />Montebello</p>
                                <p className="text-1 text-grey-2">
                                <img src={p101} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid w14 w-lg-95 marnext">
                        <div className="row">
                            <div className="col-12 col-md-5 m40 mb-md-0" data-aos="fade-right">
                            
                            </div>
                            <div className="col-12 col-md-7" data-aos="fade-left">
                                <span className="bar mt-5 text-grey-2">LA VIDA (2018-2020)</span>
                                <p className="text-1 text-grey-2">Residencial <br /> 20 Departamentos<br />Santa Gertrudis Copó</p>
                                <p className="text-1 text-grey-2">
                                <img src={p111} alt="arrow_down" className='p11 img-fluid'></img>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default withRouter(Nosotros);