import React, { Component } from 'react';
import logoWhite from '../assets/images/new/Laja_logonegro.svg';
import logoBlack from '../assets/images/new/Laja_logonegro.svg';
import { Col, Row, Container } from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import { NavLink } from 'react-router-dom';

class Burguer extends Component {
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false
        }
    }
    toggle(){
        // event.preventDefault();
        this.setState({
            open: !this.state.open
        });
    }
    render() {
        return (
            <div className={`d-md-none menu-movil ${this.props.bg ? 'bg-2' : ''}`}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col className="col-6">
                            <a href="/"><img src={(this.props.bg) ? logoBlack : logoWhite} alt="Logo" className="logo"></img></a>
                        </Col>
                        <Col className="col-6 text-right">
                            <div className={`menu menu-2 ${(this.state.open) ? 'active' : ''}`} onClick={this.toggle}>
                                <span></span>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <div className={`sidebar-left ${(this.state.open) ? 'active' : ''}`}>
                    <ul className="list-unstyled list-menu2 text-white">
                        <li className="text-uppercase">
                            <NavLink to="/concept" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.concept" defaultMessage="Concepto" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase">
                            <NavLink to="/amenities" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.amenities" defaultMessage="Amenidades" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase" onClick={() => {this.toggle();this.props.updateBody()}}>
                            <NavLink to="/houses" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.houses" defaultMessage="Casas Ensamble" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase" onClick={() => {this.toggle();this.props.updateBody()}}>
                            <NavLink to="/nosotros" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.nosotros" defaultMessage="Casas Ensamble" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase">
                            <NavLink to="/quoter" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody('bg-grey-3')}}>
                                <FormattedMessage id="app.quoter" defaultMessage="Cotizador" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase">
                            <NavLink to="/gallery" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.gallery" defaultMessage="Galería" ></FormattedMessage>
                            </NavLink>
                        </li>
                        <li className="text-uppercase">
                            <NavLink to="/contact" activeClassName="active" onClick={() => {this.toggle();this.props.updateBody()}}>
                                <FormattedMessage id="app.contact" defaultMessage="Contacto" ></FormattedMessage>
                            </NavLink>
                        </li>
                    </ul>

                    <div className="idiomas text-white">
                        {/* <a className={`${this.props.color} ${(localStorage.getItem('language') === 'es') ? 'active' : ''}`} href="#!" onClick={() => {this.setLanguage('es')}}>ESP</a> <a className={`ml-4 ${this.props.color} ${(localStorage.getItem('language') === 'en') ? 'active' : ''}`} href="#!" onClick={() => {this.setLanguage('en')}}>ENG</a> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Burguer;