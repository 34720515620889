import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import logo_footer from '../assets/images/logo_white.svg';
import icon_fb from '../assets/images/icons/icon-facebook.png';
//import icon_in from '../assets/images/icons/icon-instagram.png';
import icon_wh from '../assets/images/icons/whatsappv2.png';

class Footer extends Component {
    render() {
        return (
            <div>
                <a href="https://wa.link/erkygj" target="_blank" className="whast-flotting" rel="noreferrer">
                    <img src={icon_wh} alt="Whatsapp" className="w-max-100"></img>
                </a>

                <footer className="w-100">
                    <div className="container-fluid w15 w-lg-90">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-2 text-center text-md-left m40 mb-md-0">
                                <img src={logo_footer} alt="Laja" className="w-max-100"></img>
                            </div>
                            <div className="col-12 col-sm-6 col-md-5 m40 mb-md-0">
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-5">
                                        <ul className="list-unstyled text-uppercase mb-0">
                                            <li className="text-uppercase"><Link to="/"><FormattedMessage id="app.inicio" defaultMessage="Inicio"></FormattedMessage></Link></li>
                                            <li className="text-uppercase"><Link to="/concept"><FormattedMessage id="app.concept" defaultMessage="Concepto"></FormattedMessage></Link></li>
                                            <li className="text-uppercase"><Link to="/amenities"></Link></li>
                                            <li className="text-uppercase"><Link to="/houses">Departamentos</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-sm-6 col-lg-5">
                                        <ul className="list-unstyled text-uppercase mb-0">
                                            <li className="text-uppercase"><Link to="/quoter"><FormattedMessage id="app.quoter" defaultMessage="Cotizador"></FormattedMessage></Link></li>
                                            <li className="text-uppercase"><Link to="/gallery"><FormattedMessage id="app.gallery" defaultMessage="Galería"></FormattedMessage></Link></li>
                                            <li className="text-uppercase"><Link to="/contact"><FormattedMessage id="app.contact" defaultMessage="Contacto"></FormattedMessage></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-5 text-center text-md-right links-ext">
                                <a href="#!" className="text-uppercase d-block">Politica de Privacidad</a>
                                <a href="#!" className="text-uppercase d-block m20">
                                    <FormattedMessage id="app.todos_los_derechos_reservados" defaultMessage="Todos los derechos reservados"></FormattedMessage>
                                </a>
                                <a href="https://www.facebook.com/lajayucatan-103982611845283" target="_blank" rel="noreferrer"><img src={icon_fb} alt="Facebook" className="w-max-100"></img></a>
                                {/* <a href="#!"><img src={icon_in} alt="Facebook" className="w-max-100 ml-3"></img></a> */}
                            </div>
                            <div className="col-lg-6 col-sm-12 d-flex">

                            </div>
                            <div className="col-lg-6 col-sm-12 text-right">
                                
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;