import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import Swal from 'sweetalert2';
import ContactForm from './components/contactForm';

import './scss/contact.scss';

//Images
import mapa from './assets/images/Mapa_laja.jpg';
import pin from './assets/images/pin.svg';
import styles from './components/map_style.json';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 21.066994,
    lng: -89.630862
};

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "",
            surname: "",
            email: "",
            phone_number: "",
            message: "",
            isVerified: false,
            sending: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    handleSubmit(event){
        event.preventDefault();
        let form = document.querySelector('.validate');
        if (form.checkValidity() === false) {
            //Campos invalidos
            event.preventDefault();
            event.stopPropagation();
        }else{
            //TODO: Verificar captcha
            //Hacemos submit jeje
            if(this.state.isVerified){
                this.setState({...this.state, sending: true});
                axios.post(`${process.env.REACT_APP_API_URL}contacto`, this.state)
                .then(res => {
                    console.log(res.status);
                    if((res.status === 200) && (res.data.success)){
                        // Mostramos mensaje enviado
                        if(localStorage.getItem('language') === 'es'){
                            Swal.fire(
                                'Operación exitosa',
                                'Mensaje enviado',
                                'success'
                            )
                        }else{
                            Swal.fire(
                                'Success',
                                'Email sent',
                                'success'
                            )
                        }
                    }else{
                        if(localStorage.getItem('language') === 'es'){
                            Swal.fire(
                                'Oops!',
                                'Lo sentimos, algo salió mal',
                                'error'
                            )
                        }else{
                            Swal.fire(
                                'Oops!',
                                'Sorry, something went wrong',
                                'error'
                            )
                        }
                    }
                    this.setState({...this.state, sending: false});
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Oops!',
                        'Lo sentimos, algo salió mal',
                        'error'
                    )
                    this.setState({...this.state, sending: false});
                });
            }else{
                if(localStorage.getItem('language') === 'es'){
                    Swal.fire(
                        'Oops...',
                        'Favor de verificar el captcha',
                        'warning'
                    )
                }else{
                    Swal.fire(
                        'Oops...',
                        'Please verify the captcha',
                        'warning'
                    )
                }
            }
        }
        form.classList.add('was-validated');
    }

    verifyCallback(response){
        if(response){
            this.setState({...this.state, isVerified: true});
        }
    }
    showButton(){
        if(this.state.sending){
            return <Button className="btn-style-1 bg-grey-1 mt-3">
             <FormattedMessage
            id="app.enviando"
            defaultMessage="Enviando..."
        ></FormattedMessage>
            </Button>
        }else{
            return <Button className="btn-style-1 bg-grey-1-1 mt-3 font-weight-bold" onClick={this.handleSubmit}>
            <FormattedMessage
                id="app.enviar"
                defaultMessage="Enviar"
            ></FormattedMessage>
            </Button>
        }
    }
    setShow = (bool) => {
        this.setState({...this.state, show: bool});
    }
    render() {
        return (
            <div id="contact">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 bg-grey-1 text-white pt-5" data-aos="fade-right">
                            <div className="mw-75 p-5 m-auto">
                                <h1 className="mb-5 titulo-x2 text-white" style={{fontSize:"2.5rem"}}><FormattedMessage id="app.forma_parte_de_la_comunidad" defaultMessage="FORMA PARTE DE LA COMUNIDAD"></FormattedMessage></h1>
                                <p className="text-white pb-3" style={{fontWeight: 300, lineHeight: "30px"}}><FormattedMessage id="app.un_camino_que_toma_el_nombre_de_comunidad" defaultMessage="Un camino que toma el nombre de comunidad, un lugar donde la prioridad es el ser humano, la naturaleza, el diseño de espacios y la atmósfera libre."></FormattedMessage></p>
                                <p className="pt-3 text-gtx-mono" style={{borderTop: "1px solid white"}}>
                                    <a className="text-white" href="mailto:hola@lajayucatan.mx">HOLA@LAJAYUCATAN.MX</a> <br></br>
                                    <a className="text-white" href="tel:+529999009090"><FormattedMessage id="app.telefono" defaultMessage="TELÉFONO"></FormattedMessage>: (999) 900 9090</a>
                                </p>
                                <p className="pt-3 text-white text-gtx-mono text-uppercase" style={{borderTop: "1px solid white"}}>
                                    Tablaje 39165 Temezon Merida Yucatan , carretera Merida progreso
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 p-5" data-aos="fade-left">
                            <form className="pt-5 validate" noValidate>
                                <p style={{color:"#211F1E"}}><b><FormattedMessage id="app.dejanos_tus_datos_y_uno_de_nuestros_asesores_te_contactara" defaultMessage="Déjanos tus datos y uno de nuestros asesores te contactará."></FormattedMessage></b></p>
                                <br></br>
                                <div className="row">
                                <div className="col-lg-6 col-sm-12 mb-4">
                                <ContactForm></ContactForm>
                                </div>
                                    {/* <div className="col-lg-6 col-sm-12 mb-4">
                                        <FormattedMessage id="app.name" defaultMessage="Nombre">
                                            {placeholder =>
                                                <input type="text" className="form-control text-uppercase" onChange={(el) => {this.setState({...this.state, name: el.target.value})}} placeholder={placeholder} required></input>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-4">
                                        <FormattedMessage id="app.surname" defaultMessage="Apellido">
                                            {placeholder => 
                                                <input type="text" className="form-control text-uppercase" onChange={(el) => {this.setState({...this.state, surname: el.target.value})}} placeholder={placeholder} required></input>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-4">
                                        <FormattedMessage id="app.email" defaultMessage="Correo">
                                            {placeholder =>
                                                <input type="email" className="form-control text-uppercase" onChange={(el) => {this.setState({...this.state, email: el.target.value})}} placeholder={placeholder} required></input>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 mb-4">
                                        <FormattedMessage id="app.telefono" defaultMessage="Teléfono">
                                            {placeholder =>
                                                <input type="text" className="form-control text-uppercase" onChange={(el) => {this.setState({...this.state, phone_number: el.target.value})}} placeholder={placeholder} required></input>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <FormattedMessage id="app.message" defaultMessage="Mensaje">
                                            {placeholder =>
                                                <textarea className="w-100" rows="6" placeholder={placeholder} onChange={(el) => {this.setState({...this.state, message: el.target.value})}}></textarea>
                                            }
                                        </FormattedMessage>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <Recaptcha
                                            sitekey="6Let6r0aAAAAAN3ugGTqly6m5Svp2OHIb2m0pbXd"
                                            render="explicit"
                                            verifyCallback={this.verifyCallback}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-sm-12 text-center">
                                        {this.showButton()}
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="container-fluid w-14 w-lg-95 p-5 mt-5 mb-3">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 bg2" data-aos="fade-up">
                            <h1 className="titulo-x2 text-uppercase"><FormattedMessage id="app.nuestros_alrededores" defaultMessage="Nuestros Alrededores"></FormattedMessage></h1>
                            <span className="bar mt-5 mb-5"></span>
                        </div>
                        <div className="col-lg-7 col-sm-12 bg-rig" data-aos="zoom-in">
                            <img src={mapa} alt="mapa" className="w-100 maps"></img>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <div id="location" className="container-fluid w14 w-lg-95 mb-5">
                    <LoadScript
                        googleMapsApiKey="AIzaSyCSbru8n19oHwIA0Jp9SqNGiVQUFW351_Q"
                    >
                        <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        options={{
                            styles: styles,
                        }}
                        >
                            <Marker position={center} icon={pin}></Marker>
                        </GoogleMap>
                    </LoadScript>
                </div>
            </div>
        );
    }
}

export default Contact;