import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import './scss/cotizador.scss';
import sello from './assets/images/casa_kab/02_sello.svg';
import torre2 from './assets/images/TorreDos.jpg';

class Cotizador extends Component {
    //Cambiar el show y el loaded a false cuando se necesite mostrar las etapas!!
    constructor(props){
        super(props);
        this.state = {
            show: true,
            loaded: true
        }
    }
    componentDidMount(){
        //Habilitar esta parte cuando se tenga que mostrar las etapas!!
        // if(this.props.location.hash === "#open"){
        //     this.setState({show: true, loaded: true});
        //     var funcName = 'cargarNaboo';
        //     window[funcName]();
        // }

        //Eliminar esta parte cuando se necesiten las etapas
        var funcName = 'cargarNaboo';
        window[funcName]();
        
    }
    reload(){
        if(!this.state.loaded){
            var funcName = 'cargarNaboo';
            window[funcName]();
        }
    }
    render() {
        return (
            <div id="cotizador" className="container-fluid w14 w-lg-95 pt-5 pb-5 mb-5">
                <div className="row sect-1">
                    <div className="col-lg-7 col-sm-12 d-flex align-items-center">
                        <img src={sello} alt="sello"></img>
                        <h1 className="text-uppercase quote">
                            <FormattedMessage id="app.cotiza_con_nosotros" defaultMessage="COTIZA CON NOSOTROS"></FormattedMessage>
                        </h1>
                        
                    </div>
                    <div className="col-lg-5 col-sm-12 d-flex flex-column justify-content-center">
                        <ul className="status list-unstyled d-flex justify-content-center">
                            <li className="disponible">
                                <FormattedMessage
                                    id="app.disponible"
                                    defaultMessage="Disponible"
                                ></FormattedMessage>
                            </li>
                            <li className="apartado">
                                <FormattedMessage
                                    id="app.apartado"
                                    defaultMessage="Apartado"
                                ></FormattedMessage>
                            </li>
                            <li className="vendido">
                                <FormattedMessage
                                    id="app.vendido"
                                    defaultMessage="Vendido"
                                ></FormattedMessage>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="w-100">
                <h1 className="text-uppercase" style={{fontSize:"20px"}}> Torre 1</h1>
                    <svg className={`${localStorage.getItem('language')} ${(!this.state.show) ? '' : 'd-none'}`} version="1.1" id="et1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"viewBox="0 0 1388 694" style={{enableBackground:"new 0 0 1388 694"}}>
                        <a href="./quoter#open" onClick={() => {this.setState({show: true});this.reload()}}>
                            <polygon style={{fill:"transparent",cursor: "pointer"}} onClick={() => {this.setState({show: true})}} points="945.7,366.7 973.3,384.7 1079.3,352.3 1108.3,373 1350.3,296.3 1357.3,276 1326.3,264.3 
                                1333,240.3 1310.7,229.7 1314.7,211.7 1299,204.3 1299,201.3 1257,182.3 1250.7,183.3 1241.3,178.3 1207.7,187 1206.3,195.3 
                                1194.7,198.7 1184.3,193.3 1149.3,202.3 1149,209 1146,209.7 1136.7,205 1101,214 1098.3,222.3 1085,226 1075.3,220.7 1037.3,231.3 
                                1026.7,233.7 1003.3,222 964,231.7 961.7,244 956,241 945.3,243.7 937,238 895.3,248 892.3,305 913.3,320.7 911,345.3 "/>
                        </a>
                    </svg>
                    <div className={(this.state.show) ? '' : 'd-none'}
                        style={{
                            background: "url(https://3dbionotes.cnb.csic.es/images/loading.gif)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            minHeight: "10rem"
                        }}
                    >
                        <div id="masterplan"></div>
                    </div>
                </div>
                <div className="w-100 mb-6">
                <h1 className="text-uppercase" style={{fontSize:"20px", paddingTop:"35px" }}> Torre 2</h1>
                    <img src={torre2} className="w-max-100" alt="Torre2"></img>
                </div>
            </div>
        );
    }
}

export default withRouter(Cotizador);