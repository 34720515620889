import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';


import './scss/gallery.scss';

class Gallery extends Component {
    constructor(props){
        super(props);

        this.state = {
            categories: [],
            slides: []
        }
    }

    componentDidMount(){
        axios.get('https://laja.naboo.mx/api/v1/avances', {
            headers: {
                apikey: '90Rs5E5bnl0snEMojPTQur3BRINs7ruf'
            }
        })
        .then(res => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    categories: res.data.galerias
                }
            })
            this.loadGallery(res.data.galerias[0].gallery_id);
        })
        .catch(err => {
            console.log(err);
        })
    }

    loadGallery(id){
        if(this.state.slides.length > 0){
            this.setState({
                ...this.state,
                active: id,
                slides: []
            });
            console.log(this.state);
        }
        axios.get(`https://laja.naboo.mx/api/v1/avances/${id}/contenido`, {
            headers: {
                apikey: '90Rs5E5bnl0snEMojPTQur3BRINs7ruf'
            }
        })
        .then(response => {
            this.setState(prevState =>{
                return{
                        ...prevState,
                        slides : response.data.slides.data,
                        active: id
                }
            })
        })
        .catch(err => {
            console.log(err);
        })
    }

    showContent(){
        if(this.state.slides.length > 0){
            return this.state.slides.map((slide, index) => {
                let style = {};
                if((index === 0) || (index === 1) || (index === 2) || (index === 3)){
                    style = {marginTop: "-7rem"};
                }
                return <div key={index} className="col-lg-3 col-sm-12 mb-4" style={style}><a href={slide.cover} data-fancybox><img className="w-100" src={slide.cover} alt="gal1"></img></a></div>;
            })
        }else{
            return <Col className="text-center text-white"><h5><FormattedMessage id="app.loading" defaultMessage="Cargando..."></FormattedMessage></h5></Col>
        }
    }
    
    render() {
        return (
            <div id="gallery" className="container-fluid w14 w-lg-95">
                <p className="text-1 text-uppercase mb-4" data-aos="fade-up">
                    <FormattedMessage id="app.sobre_laja" defaultMessage="Sobre laja"></FormattedMessage>®
                </p>
                <br></br>
                
                <h2 className="text-uppercase titulo-x2" data-aos="fade-up">
                    <FormattedMessage id="app.nuestra_galeria" defaultMessage="Nuestra Galería"></FormattedMessage>
                </h2>
                <br></br>
                <br></br>
                <div className="container-fluid">
                    <div className="row align-items-end justify-content-start">
                        <div className="col-12 col-sm-8 col-lg-8">
                            <div dangerouslySetInnerHTML={{ __html: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542709410?autoplay=1&color=ffffff&title=0&byline=0&portrait=0&loop=10&background=1&muted=1" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>'}} />
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>
                <ul className="list-unstyled d-flex justify-content-end text-uppercase pb-5">
                    {this.state.categories.map((cat, index) => {
                        return <li key={cat.gallery_id} className="mr-5"><a href="#!" className={`pb-2 ${(cat.gallery_id === this.state.active) ? 'active' : ''}`} onClick={() => this.loadGallery(cat.gallery_id)}>{cat.title}</a></li>
                    })}
                </ul>
                {/* Div con bg */}
                
                <div className="bg-1">
                    {/* <div className="bg-2"></div> */}
                    <div className="hei">
                    </div>
                    <div className="row bg-grey-1 p-5">
                        {this.showContent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;