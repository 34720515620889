import React, { Component } from 'react';
import CasaKab from './components/CasaKab';
import CasaHun from './components/CasaHun';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Brochure from './components/modals/Brochure';

import './scss/houses.scss';

import fig2 from './assets/images/fig_2.svg';
import img3 from './assets/images/img3.jpg';
import img4 from './assets/images/img4.jpg';
import kab_blanco from './assets/images/kab_blanco.svg';
import hun_blanco from './assets/images/hun_blanco.svg';
import arrow_down from './assets/images/arrow_down.svg';



class Houses extends Component {
    constructor(props){
        super(props);
        let original_state = {
            kab: false,
            hun: false,
            show: false
        };
        if(this.props.location.hash === "#casaKab"){
            original_state.kab = true;
        }
        if(this.props.location.hash === "#casaHun"){
            original_state.hun = true;
        }
        this.state = original_state;
    }
    setShow = (bool) => {
        this.setState({...this.state, show: bool});
    }
    
    render() {
        return (
            <div id="houses">
                <Brochure show={this.state.show} setShow={this.setShow}></Brochure>
                <div className="section-1">
                    <div className="container-fluid w14 w-lg-95">
                        <div className="row">
                            <div className="col-12 col-md-6 m40 mb-md-0" data-aos="fade-right">
                                <h1 className="text-uppercase mw-75 titulo-x1">
                                    <FormattedMessage id="app.houses" defaultMessage="Casas Ensamble"></FormattedMessage><small>®</small>
                                </h1>
                                <button className="btn btn-cafe text-uppercase mt-3 font-weight-bold" onClick={() => {this.setShow(true)}}>
                                    <FormattedMessage id="app.descargar_brochure" defaultMessage="Descargar Brochure"></FormattedMessage>
                                </button>
                            </div>
                            <div className="col-12 col-md-6" data-aos="fade-left">
                                <span className="bar mt-5"></span>
                                <p className="text-1 text-grey-2">
                                    <FormattedMessage id="app.las_casas_de_laja_se_crearon_con_la_emocion" defaultMessage="Las casas en LAJA® se crearon con la emoción de tener todos esos grandes detalles que ayudan a disfrutar el equilibrio entre el diseño, el arte y la funcionalidad."></FormattedMessage>
                                </p>
                                <p className="text-1 text-grey-2">
                                    <FormattedMessage id="app.las_casas_ensamble_logran_un_estilo_de_vida_unico" defaultMessage="Las Casas Ensamble logran un estilo de vida único en su tipo con la capacidad de integrar lo mejor de la esencia orgánica y lo material en un mismo espacio perfectamente diseñado."></FormattedMessage>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-3 overflow-hidden">
                    <div className="container-fluid w14 w-lg-90">
                        <div className="row align-items-center position-relative justify-content-end">
                            <img src={fig2} alt="figures_section_3" className="deco-2 w-max-100" data-aos="fade-right"></img>
                            <div className="col-12 col-sm-6 col-xl-5 m30 mb-sm-0" data-aos="zoom-in">
                                <a href="#casaKab" className={`blur-hover ${(this.state.kab) ? 'selected' : ''}`} onClick={() => {return this.setState({hun: false, kab: !this.state.kab})}}>
                                    <div className="bgg" style={{backgroundImage: `url(${img3})`}}>
                                        <img src={img3} alt="img3" style={{width:"100%"}}></img>
                                    </div>
                                    <div className="s-hover position-absolute">
                                        <h2 className="text-white mt-2">3 RECÁMARAS</h2>
                                        <p className="text-white mt-2"><FormattedMessage id="app.ver_mas" defaultMessage="Ver más"></FormattedMessage></p>
                                        <img src={arrow_down} alt="arrow_down"></img>
                                    </div>
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-5" data-aos="zoom-in">
                                <a href="#casaHun" className={`blur-hover ${(this.state.hun) ? 'selected' : ''}`} onClick={() => {return this.setState({kab: false, hun: !this.state.hun})}}>
                                    <div className="bgg" style={{backgroundImage: `url(${img4})`}}>
                                        <img src={img4} alt="img3" style={{width:"100%"}}></img>
                                    </div>
                                    <div className="s-hover position-absolute">
                                        <h2 className="text-white mt-2">2 RECÁMARAS</h2>
                                        <p className="text-white mt-2"><FormattedMessage id="app.ver_mas" defaultMessage="Ver más"></FormattedMessage></p>
                                        <img src={arrow_down} alt="arrow_down"></img>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <CasaKab active={this.state.kab}></CasaKab>
                <CasaHun active={this.state.hun}></CasaHun>
            </div>
        );
    }
}

export default withRouter(Houses);