import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import ReactDOM from "react-dom";   

class ContactForm extends Component {
    
    render() {
        return <iframe width="350" height="550" frameBorder="0" src="https://lajayucatan.mx/contacto.html" />;
      }
}

export default ContactForm;